@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');


body {
    margin: 0;
    font-family: Inter 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Inter";
}