body {
  font-family: inter;
}

.button {
  font: inherit;
  padding: 1rem;
  border-radius: 4px;
  background: #041527;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #373737;
  color: #fff
}

.button--inverse {
  background: transparent;
  color: #041527;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #041527;
}

.button--danger {
  background: #E21616;
  border-color: #E21616;
}

.button--danger:hover,
.button--danger:active {
  background: #E21616;
  border-color: #E21616;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}